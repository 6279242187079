// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* No seu arquivo CSS ou em um arquivo de estilo */
.dropdown-toggle-icon-none::after {
    content: none !important;
}

.dropdown-menu {
    position: absolute;
    top: 100%; /* Posição abaixo do botão de dropdown */
    left: 0; /* Alinhar à esquerda do botão de dropdown */
    z-index: 1000; /* Valor alto para sobrepor outros elementos */
    background-color: white;
    border: 1px solid #ccc;
    /* Outros estilos conforme necessário */
  }`, "",{"version":3,"sources":["webpack://./src/components/MailboxListing/MailboxListing.css"],"names":[],"mappings":"AAAA,kDAAkD;AAClD;IACI,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,SAAS,EAAE,wCAAwC;IACnD,OAAO,EAAE,4CAA4C;IACrD,aAAa,EAAE,8CAA8C;IAC7D,uBAAuB;IACvB,sBAAsB;IACtB,uCAAuC;EACzC","sourcesContent":["/* No seu arquivo CSS ou em um arquivo de estilo */\r\n.dropdown-toggle-icon-none::after {\r\n    content: none !important;\r\n}\r\n\r\n.dropdown-menu {\r\n    position: absolute;\r\n    top: 100%; /* Posição abaixo do botão de dropdown */\r\n    left: 0; /* Alinhar à esquerda do botão de dropdown */\r\n    z-index: 1000; /* Valor alto para sobrepor outros elementos */\r\n    background-color: white;\r\n    border: 1px solid #ccc;\r\n    /* Outros estilos conforme necessário */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
